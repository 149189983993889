<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Cesiones</h1>
    </div>

    <div class="row my-3">
      <div v-if="$auth.can('analyticpoint', 'usuario_cesion_admin')" class="col-sm-12 col-lg-3">
        <div class="input-group mb-3">
          <select v-model="filtrar_solicitudes" name="filtrar_solicitudes" id="filtrar_solicitudes" class="form-control">
            <option value="todas">Todas</option>
            <option value="pendiente">Por resolver</option>
            <option value="resuelto">Resueltas</option>
          </select>
          <div class="input-group-append">
            <button class="btn btn-secondary">Filtrar</button>
          </div>
        </div>
      </div>
      <div :class="clase_admin_ctrls">
        Estatus solicitud: <span class="abierta"></span> Abierta <span class="aceptada"></span> Aceptada <span class="rechazada"></span> Rechazada <button @click="cargar_solicitudes" class="btn btn-secondary"><i class="fa-solid fa-rotate"></i> Recargar</button>
      </div>
    </div>

    <div v-if="solicitudes.length == 0" class="solicitudes">
      No hay solicitudes por el momento
    </div>
    <div v-else class="solicitudes">
      <div v-for="cesion in solicitudes_filtradas" :class="'solicitud my-2'+resolucion_solicitud(cesion)">
        <div class="row">
          <div class="col-sm-2"><strong>Solicitud ID:</strong> {{ cesion.solicitud.solicitud_id }}</div>
          <div class="col-sm-2"><strong>Nombre:</strong> {{ cesion.solicitud.nombre }}</div>
          <div class="col-sm-1"><strong>Alianza:</strong> {{ cesion.alianza.nombre }}</div>
          <div class="col-sm-1"><strong>Monto:</strong> ${{ $helper.moneyFormat(cesion.solicitud.monto_original) }}</div>
          <div class="col-sm-2"><strong>Resolución<br/>comité:</strong> {{ cesion.solicitud.resolucion }}</div>
          <div class="col-sm-1"><strong>Monto<br/>cesión:</strong> {{ cesion.monto_aceptado ? '$'+$helper.moneyFormat(cesion.monto_aceptado) : '--' }}</div>
          <div class="col-sm-2"><strong>Resolución<br/>cesión:</strong> {{ cesion.resolucion ? cesion.resolucion : '--' }}</div>
          <div class="col-sm-1"><button class="btn btn-light" @click="abrir_modal(cesion)">Revisar</button></div>
        </div>
      </div>
    </div>

    <Info v-if="show_info" :cesion="this.cesion" @close="cerrar_modal" @update="cerrar_modal(true)" />
  </div>
</template>

<script>
  import Info from './Historico/Info';

  import api from '@/apps/analyticpoint/api/comite/cesiones';

  export default {
  watch: {
  },
    components: {
      Info
    },
    data() {
      return {
        solicitudes: [],  
        cesion: null,
        show_info: false,
        filtrar_solicitudes: 'todas'
      }
    },
    mounted() {
      this.cargar_solicitudes();
    },
    methods: {
      async cargar_solicitudes() {
        try {
          let all = this.$auth.can('analyticpoint','usuario_cesion_admin') ? 1 : 0;
          this.solicitudes = (await api.obtener_cesiones_pendientes(all)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      estatus_solicitud(solicitud) {
        if (!solicitud.show)
          return 'cerrado';

        return 'abierto';
      },
      abrir_modal(cesion) {
        this.cesion = cesion;
        this.show_info = true;
      },
      cerrar_modal(update) {
        if (typeof update == 'undefined')
          update = false;

        this.solicitud_id = null;
        this.show_info = false;

        if (update)
          this.cargar_solicitudes();
      },
      resolucion_solicitud(solicitud) {
        if (!solicitud.resolucion) return '';

        return solicitud.resolucion == 'aceptado' ? ' solicitud_aprobada' : ' solicitud_rechazada';
      }
    },
    computed: {
      clase_admin_ctrls() {
        let css_class = ' info text-right';
        if (!this.$auth.can('analyticpoint', 'usuario_cesion_admin'))
          return 'col-sm-12'+css_class;

        return 'col-sm-12 col-lg-6 offset-lg-3'+css_class;
      },
      solicitudes_filtradas() {
        if (!this.$auth.can('analyticpoint','usuario_cesion_admin'))
          return this.solicitudes;

        let solicitudes = [];
        let filtro = this.filtrar_solicitudes
        this.$log.info('filtro',filtro);

        if (filtro == 'todas')
          return this.solicitudes;

        this.solicitudes.forEach(solicitud => {
          if (solicitud.estatus == filtro)
            solicitudes.push(solicitud);
        });

        return solicitudes;
      }
    }
  }
</script>

<style lang="scss" scoped>
  $solicityd_abierta: #0781cd;
  $solicitud_votada: #0d8b22;
  $solicitud_rechazada: #971212;
  .info {
    span {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      display: inline-block;
    }

    span.abierta {
      background-color: $solicityd_abierta;
    }

    span.aceptada {
      background-color: $solicitud_votada;
    }

    span.rechazada {
      background-color: $solicitud_rechazada;
    }
  }
  .solicitudes {
    .solicitud {
      background-color: $solicityd_abierta;
      // width: 90%;
      margin: auto;
      color: #fff;
      padding: 20px 15px;
      border-radius: 5px;

      .header {
        display: flexbox;

        .id_solicitd,.nombre,.monto,.resolucion,.control {
          width: 15%;
          display: inline-block;
        }

        .nombre {
          width: 50%;
        }

        .control {
          width: 5%;
          text-align: center;

          i {
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
      }
    }

    .solicitud_aprobada {
      background-color: $solicitud_votada;
    }

    .solicitud_rechazada {
      background-color: $solicitud_rechazada;
    }
  }

@media screen and (max-width: 576px) {
  .solicitudes {
    .solicitud {

      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 100%;
          text-align: right;
          display: block;

          strong {
            float: left;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 577px) and (max-width: 667px) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 45%;
        }

        .control {
          width: 15%;
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 50%;
        }

        .control {
          width: 10%;
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) and (orientation: portrait) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 45%;
        }

        .control {
          width: 10%;
        }
      }
    }
  }
}
</style>